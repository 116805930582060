import { store } from 'STORE';
import defaults from 'DEFAULTS';
import notification from '@helps/notification';
import merge from 'deepmerge';

const configs = merge(require('ROOT/configs.json'), require('ROOT/configs.prod.json'), {
  arrayMerge: (target, source) => [...new Set([...target, ...source])],
});

const HOST = localStorage.getItem('services') || configs.href.host;
const PROTOCOL = localStorage.getItem('protocol') || configs.href.protocol;
const PATH = `${PROTOCOL}://${HOST}/api/${defaults.translation.lang}/v1`;
const PATH_USERS = `${PATH}/users`;

const getAuthInfo = (type) => store.getState().userToken[type];

const getDashboardRoute = () => `${PATH}/${getAuthInfo('tokenType')}/dashboard`;

const getById = (id) => (id ? `/${id}` : '');

if (
  localStorage.getItem('services') ||
  localStorage.getItem('protocol') ||
  (defaults.isLocalhost && sessionStorage.getItem('devMode') == 1)
) {
  notification({ message: 'You are the god of the monkeys!' }, 'warn');
}

String.prototype.AXIOS = function (method = '', data, upload_callback) {
  const CONFIG = {
    method: method.trim().toLowerCase(),
    url: this.trim(),
    headers: {
      ...(store.getState().userToken.token
        ? { Authorization: `Bearer ${getAuthInfo('token')}` }
        : {}),
      'Content-Type': upload_callback ? 'multipart/form-data' : 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  };

  if (defaults.isLocalhost && sessionStorage.getItem('devMode') == 1) {
    CONFIG.headers.devMode = 1;
  }

  if (data) {
    if (['post', 'put', 'patch', 'delete'].indexOf(CONFIG.method) >= 0) {
      CONFIG.data = data;
    }

    if (CONFIG.method === 'get') {
      CONFIG.params = data;
    }
  }

  if (upload_callback) {
    CONFIG.onUploadProgress = (progressEvent) => {
      upload_callback(progressEvent);
    };
  }

  return CONFIG;
};

String.prototype.AXIOS_STREAM = function (data) {
  const CONFIG = {
    method: 'get',
    url: this.trim(),
    responseType: 'blob',
    headers: {
      ...(store.getState().userToken.token
        ? { Authorization: `Bearer ${getAuthInfo('token')}` }
        : {}),
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  };

  if (data) {
    CONFIG.params = data;
  }

  return CONFIG;
};

export default {
  url: (url) => url,
  additional: {
    early_access: `${PATH}/early-access`,
  },
  system: {
    logs: `${PATH}/log/requests/`,
    country: `${PATH}/country/`,
  },
  users: {
    history: (type) => `${PATH_USERS}/${type}/loginhistory`,
    password: (type, action) => `${PATH_USERS}/${type}/password/${action}`,
    permissions: (id = '', userType = 'aemi') =>
      `${PATH}/users/${userType}${getById(id)}/permissions`,
    roles: (id = '', role_id = '', userType = 'aemi') =>
      `${PATH}/users/${userType}${getById(id)}/roles${getById(role_id)}`,
    registration: {
      email: (type, step) => `${PATH_USERS}/${type}/verification/email/step${step}`,
      phone: (type, step) => `${PATH_USERS}/${type}/verification/phone/step${step}`,
      resend: (type, item) => `${PATH_USERS}/${type}/verification/${item}/code-resend`,
      password: (type) => `${PATH_USERS}/${type}/registration/`,
      google_auth_step_1: (type) => `${PATH_USERS}/${type}/verification/tfa/google-auth/step1`,
      google_auth_step_2: (type) => `${PATH_USERS}/${type}/verification/tfa/google-auth/step2`,
    },
    dataForRegistration: {
      client: {
        business_sectors: (type) => `${PATH_USERS}/${type}/business-sectors`,
        business_types: (type, value) => `${PATH_USERS}/${type}/business-types${getById(value)}`,
        annual_turnover: (type, sortBy = 'created_at') =>
          `${PATH_USERS}/${type}/annual-turnover?sort=${sortBy}`,
        total_net_worth: (type, sortBy = 'created_at') =>
          `${PATH_USERS}/${type}/total-net-worth?sort=${sortBy}`,
        annual_income: (type, sortBy = 'created_at') =>
          `${PATH_USERS}/${type}/annual-income?sort=${sortBy}`,
        agreements: (type, sortBy = 'order') => `${PATH_USERS}/${type}/agreements?sort=${sortBy}`,
      },
      aemi: {
        business_sectors: (type) => `${PATH}/aemi/dashboard/customers/${type}/business-sectors`,
        business_types: (type, value) =>
          `${PATH}/aemi/dashboard/customers/${type}/business-types${getById(value)}`,
        annual_turnover: (type, sortBy = 'created_at') =>
          `${PATH}/aemi/dashboard/customers/${type}/annual-turnover?sort=${sortBy}`,
        total_net_worth: (type, sortBy = 'created_at') =>
          `${PATH}/aemi/dashboard/customers/${type}/total-net-worth?sort=${sortBy}`,
        annual_income: (type, sortBy = 'created_at') =>
          `${PATH}/aemi/dashboard/customers/${type}/annual-income?sort=${sortBy}`,
        agreements: (type, id, sortBy = 'order') =>
          `${PATH}/aemi/dashboard/customers/${type}/agreements${getById(id)}?sort=${sortBy}`,
      },
    },
    authorization: {
      login: (type) => `${PATH_USERS}/${type}/login/`,
      session: (type) => `${PATH_USERS}/${type}/session/`,
      renew_token: (type) => `${PATH_USERS}/${type}/renew-token/`,
    },
    profile: {
      profile: (type) => `${PATH_USERS}/${type}/profile`,
      personal: (type, id = '') => `${PATH_USERS}/${type}/profile/personal${getById(id)}`,
      business: (type, id = '') => `${PATH_USERS}/${type}/profile/business${getById(id)}`,
      address: (type, id = '') => `${PATH_USERS}/${type}/profile/address${getById(id)}`,
      director: (type, id = '') => `${PATH_USERS}/${type}/director${getById(id)}`,
      staff: (type, id = '') => `${PATH_USERS}/${type}/staff${getById(id)}`,
      shareholder: (userType, shareholderType, id = '') =>
        `${PATH_USERS}/${userType}/shareholder/${shareholderType}${getById(id)}`,
      operational: (type, id = '') => `${PATH_USERS}/${type}/profile/operational${getById(id)}`,
      partner: (type, id = '') => `${PATH_USERS}/${type}/partner${getById(id)}`,
      businessActivity: (type, id = '') =>
        `${PATH_USERS}/${type}/profile/business-activity${getById(id)}`,
      statusCertification: (type, id = '') =>
        `${PATH_USERS}/${type}/profile/status-certification${getById(id)}`,
      verification: (type) => `${PATH_USERS}/${type}/verification`,
      verificationProgress: (type) => `${PATH_USERS}/${type}/verification/progress`,
      confirm: (type) => `${PATH_USERS}/${type}/profile/confirm`,
      success: (type, id = '') => `${PATH_USERS}/${type}/profile/success${getById(id)}`,
      progress: (type) => `${PATH_USERS}/${type}/profile/progress`,
      request: (type, target, action) => `${PATH_USERS}/${type}/verification/${target}/${action}`,
      changePassword: (type) => `${PATH_USERS}/${type}/password/change`,
      changeActualAddress: (type) => `${PATH_USERS}/${type}/profile/update-actual-address`,
      changePhoneRequest: (type) => `${PATH_USERS}/${type}/phone/change-request`,
      changePhoneVerify: (type) => `${PATH_USERS}/${type}/phone/change-request/verify`,
      changeEmailRequest: (type) => `${PATH_USERS}/${type}/email/change/request`,
      changeEmailVerify: (type) => `${PATH_USERS}/${type}/email/change/verify`,
      passphrase: (type) => `${PATH_USERS}/${type}/profile/passphrase`,
      roles: () => `${PATH_USERS}/specific-roles/users-company-api`,
    },
  },
  modals: {
    aemi: {
      modals: `${PATH}/information-request/information-popups`,
      modal: () => `${PATH}/information-request/information-popups`,
      editModal: (id) => `${PATH}/information-request/information-popups/${id}`,
      downloadModalLogs: (id) =>
        `${PATH}/information-request/information-popups/${id}/download-responses`,
      editFileDocument: (id) => `${PATH}/information-request/information-popups/attachments/${id}`,
      users: `${PATH}/information-request/information-popups/users`,
      usersNotPaginated: `${PATH}/information-request/information-popups/users/not-paginated`,
    },
    confirm: (id) => `${PATH}/information-request/information-popups/confirm/${id}`,
  },
  tfa: {
    googleQrCode: () => `${PATH_USERS}/security/tfa/google/qr`,
    phoneCode: () => `${PATH_USERS}/security/tfa/phone/send_code`,
    addPhone: (type) => `${PATH_USERS}/${type}/security/tfa/add-phone`,
    resendPhone: (type) => `${PATH_USERS}/${type}/security/tfa/add-phone/resend`,
    verifySms: (type) => `${PATH_USERS}/${type}/security/tfa/add-phone`,
    bindGoogleTfa: () => `${PATH_USERS}/security/tfa/google/bind`,
    unbindGoogleTfa: () => `${PATH_USERS}/security/tfa/google/unbind`,
    bindPhoneTfa: () => `${PATH_USERS}/security/tfa/phone/bind`,
    unbindPhoneTfa: () => `${PATH_USERS}/security/tfa/phone/unbind`,
    preferred: (type) => `${PATH_USERS}/${type}/settings/security/tfa/preferred`,
    // for auth
    googleAuthQrCode: (type) => `${PATH_USERS}/${type}/get_qr`,
    checkGoogleAuthTfa: (type) => `${PATH_USERS}/${type}/check_qr`,
    phoneAuthCode: (type) => `${PATH_USERS}/${type}/send_sms_code`,
    checkPhoneAuthTfa: (type) => `${PATH_USERS}/${type}/check_sms_code`,
  },
  communication: {
    client: {
      requests: (id) => `${PATH}/case-management/requests${getById(id)}`,
      messages: (id) => `${PATH}/case-management/messages${getById(id)}`,
      message: () => `${PATH}/case-management/message`,
      changeStatus: (id) => `${PATH}/case-management/change-status${getById(id)}`,
      unreadMessages: () => `${PATH}/case-management/new-messages`,
    },
    aemi: {
      changeAccessStatus: (id) => `${PATH}/aemi/dashboard/customers${getById(id)}/phone-tfa-access`,
      staff: (id) => `${PATH}/aemi/dashboard/customers${getById(id)}/staff`,
      reports: `${PATH}/aemi/dashboard/reports/reconciliation-statements`,
      managers: `${PATH}/aemi/dashboard/customers/management/active-managers`,
      assignToManager: `${PATH}/aemi/dashboard/customers/management/assign-to-customer`,
      assignToWallet: `${PATH}/aemi/dashboard/customers/management/assign-to-wallet`,
      allCustomers: `${PATH}/aemi/dashboard/reports/reconciliation-statements/fetch-all-customers`,
      downloadReport: `${PATH}/aemi/dashboard/reports/reconciliation-statements/download`,
      downloadWalletsReport: `${PATH}/aemi/dashboard/reports/wallet-logs/multiple-download`,
      downloadWalletReport: (id) =>
        `${PATH}/aemi/dashboard/reports/wallet-logs${getById(id)}/download`,
      unsignedTransaction: (id) =>
        `${PATH}/aemi/dashboard/customers/company/unsigned-transactions${getById(id)}`,
      signaturesTransaction: (id) =>
        `${PATH}/aemi/dashboard/customers/company/signatures${getById(id)}`,
      logs: (id) => `${PATH}/aemi/dashboard/customers/company${getById(id)}/logs`,
      walletLogs: `${PATH}/aemi/dashboard/reports/wallet-logs`,
      walletManagers: `${PATH}/aemi/dashboard/reports/wallet-managers`,
      walletManagersNotPaginated: `${PATH}/aemi/dashboard/reports/wallet-managers/?with_pagination=0`,
      walletLog: (id) => `${PATH}/aemi/dashboard/reports/wallet-logs${getById(id)}`,
      walletLogsAll: `${PATH}/aemi/dashboard/reports/wallet-logs/?with_pagination=0`,
      minSignatures: (id) =>
        `${PATH}/aemi/dashboard/customers/company${getById(id)}/min-signatures`,
      switchSignature: (id) => `${PATH}/aemi/dashboard/customers${getById(id)}/switch-signature`,
      unsignedTransactions: (id) =>
        `${PATH}/aemi/dashboard/customers/company${getById(id)}/unsigned-transactions`,
      unsignedTransactionsExport: (id) =>
        `${PATH}/aemi/dashboard/customers/company${getById(id)}/unsigned-transactions/export`,
      tfaEnabled: (id) => `${PATH}/aemi/dashboard/customers${getById(id)}/tfa-enabled`,
      phoneTfaUsing: (id) => `${PATH}/aemi/dashboard/customers${getById(id)}/phone-tfa-using`,
      messages: (id) => `${PATH}/aemi/dashboard/information-request/messages${getById(id)}`,
      message: () => `${PATH}/aemi/dashboard/information-request/message`,
      changeStatus: (id) =>
        `${PATH}/aemi/dashboard/information-request/change-status${getById(id)}`,
      unreadMessages: () => `${PATH}/aemi/dashboard/information-request/new-messages`,
    },
  },
  notifications: {
    aemi: {
      list: `${PATH}/aemi/dashboard/notifications`,
      subscriptions: `${PATH}/aemi/dashboard/notifications/subscriptions`,
      subscribe: (id) => `${PATH}/aemi/dashboard/notifications/subscribe${getById(id)}`,
      unsubscribe: (id) => `${PATH}/aemi/dashboard/notifications/unsubscribe${getById(id)}`,
      subscribeAll: `${PATH}/aemi/dashboard/notifications/subscribe-to-all-notifications`,
      unsubscribeFromAll: `${PATH}/aemi/dashboard/notifications/unsubscribe-from-all-notifications`,
      apiNotificationMarkRead: (id) =>
        `${PATH}/aemi/dashboard/notifications/api-notification-mark-read${getById(id)}`,
      apiNotification: (id) =>
        `${PATH}/aemi/dashboard/notifications/api-notification${getById(id)}`,
      apiNotificationDelete: (id) =>
        `${PATH}/aemi/dashboard/notifications/delete-api-notification${getById(id)}`,
    },
  },
  kyb: {
    companies_house: {
      search: (title = '') => `${PATH}/kyb/ch/company/search/${title}`,
      info: (company_id, parameter = '') => `${PATH}/kyb/ch/company/${company_id}/${parameter}`,
    },
    credit_safe: {
      basic: (company_id) => `${PATH}/kyb/cs/company/basic/${company_id}`,
      full: (company_id) => `${PATH}/kyb/cs/company/full/${company_id}`,
    },
    report: (business_id) => `${PATH}/aemi/dashboard/report/business/${business_id}`,
  },
  kyc: {
    jumio: {
      verification: `${PATH}/kyc/jumio/createVerification/`,
      verificationPersonal: (profile_id) =>
        `${PATH}/kyc/jumio/createVerification/personal/${profile_id}`,
      update: `${PATH}/kyc/jumio/verification/`,
      getImages: (profile_id) => `${PATH}/kyc/jumio/scan${getById(profile_id)}`,
    },
    sumsub: {
      progress: `${PATH}/kyc/sumsub/verification/`,
      verification: `${PATH}/kyc/sumsub/createVerification/`,
      verificationPersonal: (profile_id) =>
        `${PATH}/kyc/sumsub/createVerification/personal/${profile_id}`,
      result: `${PATH}/kyc/sumsub/verificationResult/`,
      resendVerification: (profile_id) =>
        `${PATH}/aemi/dashboard/report/personal/${profile_id}/invitation`,
    },
    report: (person_id) => `${PATH}/aemi/dashboard/report/personal/${person_id}`,
  },
  case_management: {
    cases: (id = '') => `${PATH}/case-management/cases${getById(id)}`,
    export: (id = '') => `${PATH}/case-management/cases/export${getById(id)}`,
    statuses: () => `${PATH}/case-management/cases/getStatuses`,
    tags: `${PATH}/case-management/tags`,
    comments: (id) => `${PATH}/case-management/cases/${id}/comments`,
    history: (id) => `${PATH}/case-management/cases/${id}/report`,
    complyAdvantage: (id = '') => `${PATH}/kyc/ca/searches/${id}/entities`,
    toggleSubscription: (id) => `${PATH}/case-management/cases/toggle-subscription${getById(id)}`,
  },
  banking: {
    modulr: {
      status: `${PATH}/banking/modulr/customers/status`,
      create: `${PATH}/banking/modulr/customers`,
    },
    payment_codes: `${PATH}/banking/payment-codes`,
    payment_codes_export: `${PATH}/banking/payment-codes/export`,
    payment_code: (id = '') => `${PATH}/banking/aemi/payment-codes${getById(id)}`,
    transactionsExport: `${PATH}/aemi/dashboard/banking/clients/transactions/export`,
    transactions: `${PATH}/aemi/dashboard/banking/clients/transactions`,
    amlTransactions: (id = '') => `${PATH}/aemi/dashboard/banking/aml/transactions${getById(id)}`,
    approveAmlTransaction: (id = '') =>
      `${PATH}/aemi/dashboard/banking/aml/transactions${getById(id)}/approve`,
    advancedAmlTransaction: (id = '') =>
      `${PATH}/aemi/dashboard/banking/aml/transactions${getById(id)}/advanced-payment`,
    declineAmlTransaction: (id = '') =>
      `${PATH}/aemi/dashboard/banking/aml/transactions${getById(id)}/reject`,
    returnAdjustmentAmlTransaction: (id = '') =>
      `${PATH}/aemi/dashboard/banking/aml/transactions${getById(id)}/return-adjustment`,
    paymentStatusColorAmlTransaction: (id = '') =>
      `${PATH}/aemi/dashboard/banking/aml/transactions${getById(id)}/payment-label-color`,
    completeTransaction: (id = '') => `${PATH}/aemi/dashboard/payments${getById(id)}/complete`,
    transfer_confirmation: (type, id) =>
      `${PATH}/banking/${type}/transfer-confirmation${getById(id)}`,
    virtual_wallet_create: (type) => `${PATH}/${type}/dashboard/wallet`,
    clear_junction_create: `${PATH}/banking/clearjunction/accounts`,
    edit_wallet: (type) => `${PATH}/${type}/dashboard/banking/clients/wallets/update`,
    limited_partner_interest: (type) =>
      `${PATH}/${type}/dashboard/banking/clients/wallets/interest-list`,
    statuses_list: (type) => `${PATH}/${type}/dashboard/banking/clients/wallets/statuses-list`,
    set_status: (type, id) =>
      `${PATH}/${type}/dashboard/banking/clients/wallets${getById(id)}/set-status`,
    wallets: (type) => `${PATH}/${type}/dashboard/banking/clients/wallets`,
    wallets_export: (type) => `${PATH}/${type}/dashboard/banking/clients/wallets/export`,
    signature: (id) => `${PATH}/banking/company/transactions${getById(id)}/signatures`,
    wallet_update_info: (type, id) =>
      `${PATH}/${type}/dashboard/banking/clients/wallets${getById(id)}/update-info`,
    update_currencies: (type, id) =>
      `${PATH}/${type}/dashboard/banking/clients/wallets${getById(id)}/update-currencies`,
    clientWallets: (type) => `${PATH}/banking/${type}/client-wallets`,
    aemi: {
      currencies: () => `${getDashboardRoute()}/banking/currencies`,
      beneficiaries: (id = '', type = '') =>
        `${PATH}/${type}/dashboard/beneficiaries${getById(id)}`,
      beneficiariesAll: (id = '', type = '') =>
        `${PATH}/${type}/dashboard${getById(id)}/beneficiaries/all`,
      beneficiariesGetAccount: (id = '', type = '') =>
        `${PATH}/${type}/dashboard/beneficiaries${getById(id)}/accounts`,
      currenciesAll: () => `${getDashboardRoute()}/banking/currencies-all`,
      statement_payments: () => `${getDashboardRoute()}/banking/clients/statement`,
      balance_report: () => `${getDashboardRoute()}/reports/wallet-statements`,
    },
    client: {
      currencies: (type) => `${PATH}/banking/${type}/currencies`,
      paymentTypes: () => `${PATH}/banking/transactions/payment-execution-types`,
      beneficiaries: (id = '', type = '') => `${PATH}/banking/${type}/beneficiaries${getById(id)}`,
      internalBeneficiaries: (id = '', type = '') =>
        `${PATH}/banking/${type}/internal-beneficiary${getById(id)}`,
      beneficiariesGetAccount: (id = '', type = '') =>
        `${PATH}/banking/${type}/beneficiaries${getById(id)}/accounts`,
      currenciesAll: () => `${PATH}/banking/currencies-all`,
      statement_payments: (type) => `${PATH}/banking/${type}/statement`,
    },
    beneficiaries_approved: (type = '') => `${PATH}/banking/${type}/beneficiaries-approved`,
    beneficiariesReactivate: (id = '', type = '') =>
      `${PATH}/banking/${type}/beneficiary-reactivate${getById(id)}`,
    beneficiariesDeleteAccount: (id = '') => `${PATH}/banking/beneficiaries/accounts${getById(id)}`,
    beneficiariesAccount: (id = '', type = '') =>
      `${PATH}/banking/${type}/beneficiaries/accounts${getById(id)}`,
    beneficiariesGetActiveAccount: (id = '', type = '') =>
      `${PATH}/banking/${type}/beneficiaries${getById(id)}/active-accounts`,
    checkAccountIban: (type, iban) => `${PATH}/banking/${type}/check/${iban}`,
    checkInternalAccountIban: (type, iban) =>
      `${PATH}/banking/${type}/internal-beneficiary/${iban}`,
    accounts: (id = '', type = '') => `${PATH}/banking/${type}/wallets${getById(id)}`,
    payments: (id = '', type = '') => `${PATH}/banking/${type}/transactions${getById(id)}`,
    adjustment: (id = '', type = '') =>
      `${PATH}/banking/${type}/transactions${getById(id)}/adjustment`,
    calc_fee: (type) => `${PATH}/banking/${type}/transactions/commission`,
    pricing_plans: (userType, id = '') => `${PATH}/banking/${userType}/pricing-plans${getById(id)}`,
    pricing_plan_positions: (userType) => `${PATH}/banking/${userType}/pricing-plan-positions`,
    pricing_plan_set_default: (userType, id = '') =>
      `${PATH}/banking/${userType}/pricing-plan-set-default/${id}`,
    pricing_plan_by_vendor: (userType) => `${PATH}/banking/${userType}/pricing-plan-by-vendor`,
    pricing_plan_assign_to_customer: (userType) =>
      `${PATH}/banking/${userType}/pricing-plan-assign-to-customer`,
    tariff_commission: (userType) => `${PATH}/banking/${userType}/tariff-plans/create`,
    set_tariff_plan: (id) =>
      `${PATH}/aemi/dashboard/banking/clients/wallets${getById(id)}/set-tariff-plan`,
    change_tariff_plan: (id) =>
      `${PATH}/aemi/dashboard/banking/clients/wallets${getById(id)}/change-tariff-plan`,
    tariff_plans: (userType, id = '') => `${PATH}/banking/${userType}/tariff-plans${getById(id)}`,
    tariff_plans_export: (userType, id = '') =>
      `${PATH}/banking/${userType}/tariff-plans/export${getById(id)}`,
    tariff_plans_manual: (userType, id = '') =>
      `${PATH}/banking/${userType}/tariff-plans${getById(id)}/manual-commissions`,
    active_currencies: () => `${getDashboardRoute()}/banking/currencies`,
    vault_currencies: () => `${getDashboardRoute()}/banking/currencies-vault`,
    currencies_vault: (network) =>
      `${getDashboardRoute()}/banking/currencies-vault?network=${network}`,
    active_vendors: () => `${getDashboardRoute()}/banking/vendors`,
  },
  customers: {
    overview: (type = '') => `${getDashboardRoute()}/customers/${type}`,
    export: (type = '') => `${getDashboardRoute()}/customers/${type}/export`,
    customerInfo: (id = '') => `${getDashboardRoute()}/customers${getById(id)}`,
    updateCompany: (id = '') => `${getDashboardRoute()}/customers/company${getById(id)}`,
    updateIndividual: (id = '') => `${getDashboardRoute()}/customers/individual${getById(id)}`,
    updateCustomerDescription: (id = '') =>
      `${getDashboardRoute()}/customers/description${getById(id)}`,
    partners: (id = '') => `${getDashboardRoute()}/customers/partners${getById(id)}`,
    directors: (id = '') => `${getDashboardRoute()}/customers/directors${getById(id)}`,
    changeMainDirector: (id) =>
      `${PATH}/aemi/dashboard/customers/directors${getById(id)}/assign-as-business-profile`,
    verificationRequired: (id) =>
      `${PATH}/aemi/dashboard/customers${getById(id)}/verification-required`,
    shareholdersPersonal: (id = '') =>
      `${getDashboardRoute()}/customers/shareholders/personal${getById(id)}`,
    shareholdersBusiness: (id = '') =>
      `${getDashboardRoute()}/customers/shareholders/business${getById(id)}`,
    updateAgreements: (id = '', type = '') =>
      `${getDashboardRoute()}/customers/${type}/agreements${getById(id)}`,
    updateBusinessActivity: (id = '', type = '') =>
      `${getDashboardRoute()}/customers/${type}/business-activity${getById(id)}`,
    onboarding: () => `${getDashboardRoute()}/onboarding/status`,
    status: () => `${getDashboardRoute()}/onboarding/status`,
    block: (id) => `${getDashboardRoute()}/customers/block${getById(id)}`,
    unblock: (id) => `${getDashboardRoute()}/customers/unblock${getById(id)}`,
    userLevel: (id) => `${getDashboardRoute()}/customers/company/user-level${getById(id)}`,
    userLevelClient: (id) => `${PATH_USERS}${getById(id)}/user-levels`,
    userLevels: () => `${getDashboardRoute()}/customers/company/user-levels`,
    riskLevel: () => `${getDashboardRoute()}/customers/company/risk-level`,
    userRiskLevel: (id) => `${getDashboardRoute()}/customers/company/risk-level${getById(id)}`,
  },
  team: `${PATH}/users/aemi/team`,
  staffManualFinish: `${PATH}/users/company/staff/manually-finish`,
  deletedTeamUsers: `${PATH}/users/aemi/deleted`,
  roles: `${PATH}/users/aemi/roles`,
  role: (id = '') => `${PATH}/users/aemi/roles${getById(id)}`,
  deleteAemi: (id = '') => `${PATH}/users/aemi/profile/delete${getById(id)}`,
  rolePermissions: (id = '') => `${PATH}/users/aemi/roles${getById(id)}/permissions`,
  rolePermission: (id = '', permId = '') =>
    `${PATH}/users/aemi/roles${getById(id)}/permissions${getById(permId)}`,
  roleCreate: (id = '') => `${PATH}/users/aemi/roles${getById(id)}`,
  permissions: `${PATH}/users/aemi/permissions`,
  configurations: `${PATH}/aemi/dashboard/configurations`,
  get_users: `${PATH}/aemi/dashboard/users`,
  filemanager: {
    identificationDocuments: (id = '') =>
      `${PATH}/filemanager/documents/identification${getById(id)}`,
    getAllCustomerFiles: (id = '') => `${PATH}/filemanager/documents/business${getById(id)}`,
    operationalDocuments: (id = '') => `${PATH}/filemanager/documents/operational${getById(id)}`,
    directorDocuments: (id = '') => `${PATH}/filemanager/documents/director${getById(id)}`,
    aemiDocuments: (id = '') => `${PATH}/aemi/filemanager/document/upload${getById(id)}`,
    deleteAemiDocuments: (id = '') => `${PATH}/aemi/filemanager/documents${getById(id)}`,
    staffDocuments: (id = '') => `${PATH}/filemanager/documents/staff${getById(id)}`,
    shareholderDocuments: (id = '') => `${PATH}/filemanager/documents/shareholder${getById(id)}`,
    caseDocuments: (id = '') => `${PATH}/filemanager/documents/case${getById(id)}`,
    download: (id = '') => `${PATH}/filemanager/documents/download${getById(id)}`,
    downloadByUser: (id = '', type = '') =>
      `${PATH}/filemanager/documents${getById(type)}/download${getById(id)}`,
    upload: (id = '') => `${PATH}/aemi/filemanager/document/upload${getById(id)}`,
    delete: (id = '') => `${PATH}/aemi/filemanager/documents${getById(id)}`,
    catalogs: (id = '') => `${PATH}/aemi/filemanager/documents/catalogs${getById(id)}`,
    infoRequestMessageDocuments: (type, id = '') =>
      `${PATH}/filemanager/documents/information-request/${type}${getById(id)}`,
  },
  geo: {
    postalCode: (countryCode) => `${PATH}/geo/country?filter[code]=${countryCode}`,
  },
  statistics: {
    vendorUsage: (userType = 'aemi') => `${PATH}/${userType}/dashboard/statistics/vendors`,
  },
};
